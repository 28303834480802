import React from "react";
import ThankyoufirstImage from "../assets/thankyouImage.png";
import StarThankyou from "../assets/StarThankyou.png";

import ThankyouSecondImage from "../assets/ThankyouSecondImage.png";
import { Swiper, SwiperSlide } from "swiper/react";
import worldcareLogoThankyou from "../assets/worldcareLogoThankyou.png";
import TickThankyou from "../assets/TickThankyou.png";

import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import _, { repeat } from "lodash";

const ThankyouFirstSection = () => {
  const reviewData = [
    {
      rev: "Requesting an appointment is as simple as entering a few details about your request, choosing a timeframe, and how you'd like to be contacted. The doctors are highly professional and helpful.",
      by: "John A.",
    },
    {
      rev: "All medical providers I have seen are professional and I have received nothing less than stellar care from them.",
      by: "Cathy P.",
    },
    {
      rev: "This was the most efficient interaction I've ever had in healthcare.",
      by: "Frank B.",
    },
  ];

  return (
    <div className="main_first_section">
      {/* <header>
        <div className="container">
          <img src={worldcareLogoThankyou} alt="logo" />
        </div>
      </header> */}

      <div
        className="firstSection"
        // style={{ background: `url(${ThankyouBack}) no-repeat` }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="content_section">
                <div className="iconImage d-flex align-items-center gap-3">
                  <img src={TickThankyou} alt="tick" />
                  <h2 className="mb-0">Thanks for registering!</h2>
                </div>
                <p>
                  If you haven't received it already, you will be emailed a
                  welcome kit that has your member number to access our system.
                  If you do not see it in your inbox, please check your spam.
                </p>
                <div className="button_section d-flex align-items-center gap-3">
                  <a href="https://www.worldcaremd.com/#services">
                    <button className="btn btn-bg">Continue</button>
                  </a>

                  <a
                    href="https://worldcaremd.navigohealth.com"
                    className="login"
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="image_section">
                <img src={ThankyoufirstImage} alt="consult" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="secondSection">
        <div className="container">
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            effect="Fade"
            speed={2000}
            slidesPerView={1}
            loop={true}
            modules={[EffectFade, Navigation, Pagination, Autoplay]}
            // modules={[Pagination]}
            className="mySwiper"
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
          >
            {reviewData?.map((row) => (
              <SwiperSlide key={row}>
                <div className="row">
                  <div className="d-flex gap-4">
                    <div className="iconSection">
                      <img src={StarThankyou} />
                    </div>
                    <div className="contentSection">
                      <p>{row?.rev}</p>
                      <p className="name_test mb-0">{row?.by}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="ThirdSection overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="thirdSectionContent">
                <div className="request d-flex gap-4">
                  <div className="buttonCount">
                    <button>1</button>
                  </div>
                  <div className="consultContent">
                    <h4>
                      Request a <span>Consult</span>
                    </h4>
                    <p>
                      Consult with one of our board-certified physicians via
                      secure bi-directional video or phone day or night - 24/7.
                    </p>
                  </div>
                </div>
                <div className="request d-flex gap-4">
                  <div className="buttonCount">
                    <button>2</button>
                  </div>
                  <div className="consultContent">
                    <h4>
                      Talk with A <span>Doctor</span>
                    </h4>
                    <p>
                      Receive diagnosis and treatment by our physicians to get
                      quality care and peace of mind wherever you are day or
                      night.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="thirdSectionImage">
                <img src={ThankyouSecondImage} alt="consult" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankyouFirstSection;
