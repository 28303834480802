import React from "react";

const ArrowIcon = ({ width = 24, height = 24, color = "black", onClick }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7405 15.0335L18.1393 10.6347C18.3178 10.4552 18.418 10.2123 18.418 9.9591C18.418 9.70592 18.3178 9.46303 18.1393 9.28347C18.0502 9.19365 17.9442 9.12236 17.8274 9.0737C17.7106 9.02505 17.5854 9 17.4589 9C17.3324 9 17.2071 9.02505 17.0903 9.0737C16.9735 9.12236 16.8675 9.19365 16.7785 9.28347L12.3893 13.6918C12.3002 13.7816 12.1942 13.8529 12.0774 13.9016C11.9606 13.9502 11.8354 13.9753 11.7089 13.9753C11.5824 13.9753 11.4571 13.9502 11.3403 13.9016C11.2235 13.8529 11.1175 13.7816 11.0285 13.6918L6.63929 9.28347C6.4601 9.10302 6.21657 9.00113 5.96226 9.00023C5.70796 8.99933 5.46371 9.0995 5.28325 9.27868C5.10279 9.45787 5.00091 9.7014 5.00001 9.95571C4.99911 10.21 5.09927 10.4543 5.27846 10.6347L9.67721 15.0335C10.2163 15.5719 10.947 15.8743 11.7089 15.8743C12.4708 15.8743 13.2015 15.5719 13.7405 15.0335Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowIcon;
