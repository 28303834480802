import styled from "styled-components";

export const Adapolicystyle = styled.div`
  .custom-pad {
    padding: 80px 0;
  }

  .privacy_container h2 {
    font-size: 22px;
    margin-bottom: 12px;
  }

  .privacy_container p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }

  .privacy_container section:not(:last-child) {
    margin-bottom: 30px;
  }

  ul li {
    margin-bottom: 5px;
    position: relative;
    padding-left: 15px;
  }

  ul li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px;
    width: 7px;
    height: 7px;
    background: #5061f9;
    border-radius: 10px;
  }

  @media (max-width: 991px){
    .custom-pad {
      padding: 50px 0;
    }
      .privacy_container h2 {
      font-size: 20px;
    }
}

 @media (max-width: 767px){
  .privacy_container h2 {
    font-size: 18px;
  }
    .privacy_container p, ul li{
      font-size: 14px;
    }
}
`;
