import React from "react";
import { CookiePolicyStyle } from "./CookiePolicyStyle";

const CookiePolicy = () => {
  return (
    <CookiePolicyStyle>
      <section className="Banner-privacy">
        <div className="container-fluid p-0">
          <div className="about-banner">
            <div className="container">
              <h1>
                Cookie Policy for <span className=""> WorldCare MD </span>{" "}
              </h1>
              <p className="image-aboutus-para">
                {" "}
                This is the Cookie Policy for WorldCare MD, accessible from{" "}
                <a
                  href="https://www.worldcaremd.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.worldcaremd.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="custom-pad privacy_container">
        <div className="container">
          <section>
            <h2>What Are Cookies</h2>
            <p>
              As is common practice with almost all professional websites, this
              site uses cookies, which are tiny files that are downloaded to
              your computer, to improve your experience. This page describes
              what information they gather, how we use it, and why we sometimes
              need to store these cookies. We will also share how you can
              prevent these cookies from being stored; however, this may
              downgrade or 'break' certain elements of the site's functionality.
            </p>
          </section>

          <section>
            <h2>How We Use Cookies</h2>
            <p>
              We use cookies for a variety of reasons detailed below.
              Unfortunately, in most cases, there are no industry standard
              options for disabling cookies without completely disabling the
              functionality and features they add to this site. It is
              recommended that you leave on all cookies if you are not sure
              whether you need them or not in case they are used to provide a
              service that you use.
            </p>
          </section>

          <section>
            <h2>Disabling Cookies</h2>
            <p>
              You can prevent the setting of cookies by adjusting the settings
              on your browser (see your browser Help for how to do this). Be
              aware that disabling cookies will affect the functionality of this
              and many other websites that you visit. Disabling cookies will
              usually result in also disabling certain functionality and
              features of this site. Therefore, it is recommended that you do
              not disable cookies.
            </p>
          </section>

          <section>
            <h2>The Cookies We Set</h2>
            <ul>
              <li>
                <strong>Account related cookies</strong>: If you create an
                account with us, then we will use cookies for the management of
                the signup process and general administration. These cookies
                will usually be deleted when you log out; however, in some
                cases, they may remain afterwards to remember your site
                preferences when logged out.
              </li>
              <li>
                <strong>Login related cookies</strong>: We use cookies when you
                are logged in so that we can remember this fact. This prevents
                you from having to log in every single time you visit a new
                page. These cookies are typically removed or cleared when you
                log out to ensure that you can only access restricted features
                and areas when logged in.
              </li>
              <li>
                <strong>Forms related cookies</strong>: When you submit data
                through a form such as those found on contact pages or comment
                forms, cookies may be set to remember your user details for
                future correspondence.
              </li>
            </ul>
          </section>

          <section>
            <h2>Third Party Cookies</h2>
            <p>
              In some special cases, we also use cookies provided by trusted
              third parties. The following section details which third party
              cookies you might encounter through this site.
            </p>
            <ul>
              <li>
                This site uses Google Analytics, which is one of the most
                widespread and trusted analytics solutions on the web for
                helping us to understand how you use the site and ways that we
                can improve your experience. These cookies may track things such
                as how long you spend on the site and the pages that you visit
                so we can continue to produce engaging content. For more
                information on Google Analytics cookies, see the official{" "}
                <a
                  href="https://policies.google.com/technologies/cookies?hl=en-US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Analytics page
                </a>
                .
              </li>
              <li>
                From time to time, we test new features and make subtle changes
                to the way that the site is delivered. When we are still testing
                new features, these cookies may be used to ensure that you
                receive a consistent experience whilst on the site whilst
                ensuring we understand which optimizations our users appreciate
                the most.
              </li>
            </ul>
          </section>

          <section>
            <h2>More Information</h2>
            <p>
              Hopefully, that has clarified things for you and, as was
              previously mentioned, if there is something that you aren't sure
              whether you need or not, it's usually safer to leave cookies
              enabled in case it does interact with one of the features you use
              on our site. For more general information on cookies, please read
              the{" "}
              <a
                href="https://policies.google.com/privacy?hl=en-US"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookies Policy article
              </a>
              .
            </p>
            <p>
              However, if you are still looking for more information, then you
              can contact us through one of our preferred contact methods:
            </p>
            <ul>
              <li>
                Email:{" "}
                <a href="mailto:support@worldcaremd.com">
                  support@worldcaremd.com
                </a>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </CookiePolicyStyle>
  );
};

export default CookiePolicy;
