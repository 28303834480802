import styled from "styled-components";

export const ServicesDetailStyle = styled.div`
        display: flex;
        align-items: center;
  .ServiceDetailcontent {
    background-color: rgb(255, 255, 255);
    padding: 41px 56px 41px 44px;
    border-radius: 25px;
    position: relative;
    max-width: 1020px;
    margin: auto;
    overflow: hidden;
    height: calc(100vh - 100px);
    max-height: 708px;
    overflow-x: hidden;
    overflow-y: auto;
    @media screen and (max-width:1199px){
    max-width:100%;    
    padding:25px 15px;
    } 
     @media screen and (max-width:992px){
   
    } 
    .modal-header {
      justify-content: flex-start;
      gap: 0 32px;
      margin-bottom: 40px;
      z-index: 1;
      position: relative;
      flex-wrap:wrap;
       @media screen and (max-width:992px){
          margin-bottom: 15px;
    } 
      h2 {
        position: relative;
        font-size: 25px;
        font-weight: 700;
        margin: 0;
        &:after {
          content: "";
          position: absolute;
          width: 1px;
          height: 100%;
          background: #c4c4c4;
          left: -16px;
        }
           @media screen and (max-width:992px){
          margin-top: 15px;
    } 
      }
      .close-icon {
        position: absolute;
        right: 0px;
        top: 0px;
      }
    }
    .modal-body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
      z-index: 1;
      @media screen and (max-width:992px){
       grid-template-columns: repeat(1, 1fr);
      }
      .imageClass {
        border-radius: 15px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
           @media screen and (max-width:992px){
     height:400px;
      }
      }
      h3 {
        font-size: 18px;
        margin-bottom: 20px;
      }

      ul {
        li {
          display: flex;
          gap: 12px;
          margin-bottom: 12px;
        }
      }
    }
    .bgImage {
      position: absolute;
      top: -90px;
      right: -62px;
      z-index: 0;
    }
  }
`;
