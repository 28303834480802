import React from "react";
import ThankyouComponent from "./ThankyouComponent";
import ThankYouStyleCss from "./Thankyou.style";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-flip";
import "swiper/css/effect-creative";

function ThankYouPage() {
  return (
    <ThankYouStyleCss>
      <div className="Thank_u">
        <div className="container_main">
          <ThankyouComponent />
        </div>
      </div>
    </ThankYouStyleCss>
  );
}

export default ThankYouPage;
