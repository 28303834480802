import styled from "styled-components";

export const PrivacyPolicyStyleCss = styled.div`
  .privacy {
    background: url("https://main.d36b9rzlracsit.amplifyapp.com/static/media/bg-1.691d588989f6b9de220f.png")
      no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .privacy .container.pb-5 {
    padding: 60px 15px;
  }

  .privacy .contentClass h2 {
    font-size: 24px;
    background: #1877f62e;
    background-size: cover;
    border-left: 5px solid #1877f6;
    padding: 8px 15px;
    color: #000;
    font-weight: 600;
    margin-bottom: 20px;

    @media (max-width: 991px) {
      line-height: 1.8;
      font-size: 16px;
    }
  }

  .privacy .contentClass h2:not(:first-child) {
    margin-top: 30px;
  }

  .privacy .contentClass p {
    font-size: 18px;
    padding: 0;
    @media (max-width: 991px) {
      font-size: 16px;
      padding: 0px;
      line-height: 1.7;
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
    b {
      position: relative;
      padding-left: 25px;
      @media (max-width: 767px) {
        padding-left: 20px;
      }
      :before {
        content: "";
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #0399ea;
        position: absolute;
        left: 0;
        top: 3px;
        @media (max-width: 767px) {
          width: 10px;
          height: 10px;
          top: 5px;
        }
      }
    }
  }
`;
