import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import primaryCareImg from "../assets/primaryCareImg.png";
import dermatologyImg from "../assets/dermatologyImg.png";
import behavioralHealthImg from "../assets/behavioralHealthImg.png";
import petCareImg from "../assets/petCareImg.png";
import logo from "../../Components/assets/logo.png";
import Appointment from "../Home/Appointment";
import ServicesModel from "./Components/ServiceModel/ServicesModel";
import ServicedetailModel from "./Components/ServiceDetailModel/ServicedetailModel";
import ArrowIcon from "../Icons/ArrowIcon";

const services = [
  {
    id: 1,
    title: "Primary Care",
    description:
      "Whether you're aiming to maintain your health now to save on future costs, managing a chronic condition that needs ongoing care, or seeking a provider who can address both your immediate concerns and long-term goals, WorldCare MD's primary care providers are here for you.",
    list: [
      "Respiratory Infections",
      "Allergies",
      "Hypertension",
      "Diabetes Management",
      "Back and Joint Pain",
      "Sexual and Reproductive Health",
      "Preventative Care and Screenings",
      "Chronic Condition Management and MORE",
    ],
    image: primaryCareImg,
    url: "https://worldcaremd.navigohealth.com/",
  },
  {
    id: 2,
    title: "Dermatology",
    description:
      "With online dermatology care, there's no need to wait weeks for an appointment. Start a virtual consultation anytime with one of our U.S. board-certified dermatologists. Receive a diagnosis, personalized treatment plan, and prescriptions if necessary, all within 24 hours or less.",
    list: [
      "Acne",
      "Dermatitis",
      "Eczema",
      "Poison Ivy",
      "Rash",
      "Psoriasis",
      "Rosacea",
      "Skin Infections and MORE",
    ],
    image: dermatologyImg,
    url: "https://worldcaremd.navigohealth.com/",
  },
  {
    id: 3,
    title: "Behavioral Health",
    description:
      "Experience lasting peace of mind with the right therapist, all from the comfort of your home. It's all about genuine conversations and meaningful progress. Our compassionate, licensed therapists are here to support a wide range of mental health needs.",
    list: [
      "Depression",
      "Grief and Loss",
      "Drug and Alcohol Issues",
      "Eating Disorders",
      "Smoking Cessation",
      "Anxiety",
      "ADHD",
      "Marriage Issue and MORE",
    ],
    image: behavioralHealthImg,
    url: "https://www.betterhelp.com/worldcaremd/",
  },
  {
    id: 4,
    title: "Pet Care & Training",
    description:
      "Virtual veterinary care bridges the gap with convenient, high-quality veterinary telemedicine to meet your pet's needs when in-clinic care isn't necessary. Work with a licensed dog trainer from home to meet your goals.",
    list: [
      "Personalized feeding recommendations",
      "Vomiting or diarrhea concerns",
      "Coughing and sneezing",
      "New puppy or kitten concerns",
      "Itchy skin and ears",
      "Routine care needs",
      "Elder pet care",
      "Training and MORE",
    ],
    image: petCareImg,
    url: "https://worldcare.teletails.com/",
  },
];

const Header = () => {
  const navigate = useNavigate();

  const servicesRef = useRef(null);
  const servicesModelRef = useRef(null);

  const toggle = () => {
    var element = document.getElementById("navbarSupportedContent");
    element.classList.remove("show");
  };

  const [scrollActive, setScrollActive] = useState(false);
  const [isOpen, setIsOpen] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const handleLearnMoreClick = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    handleMouseLeave();
    setIsModalOpen(false);
    setSelectedService(null);
  };

  const handleIsOpen = (value) => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollActive(window.scrollY > 20);
    });
  }, []);

  const handleClick = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const handleAboutClick = () => {
    navigate("/about");
    window.scrollTo(0, 0);
    toggle();
  };

  const handleMouseLeave = () => {
    if (document.body.classList.contains("services-hover")) {
      document.body.classList.remove("services-hover");
    } else {
      document.body.classList.add("services-hover");
    }
  };

  const handleClickOutside = (event) => {
    if (
      servicesRef.current &&
      !servicesRef.current.contains(event.target) &&
      servicesModelRef.current &&
      !servicesModelRef.current.contains(event.target)
    ) {
      document.body.classList.remove("services-hover");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <Appointment handleIsOpen={handleIsOpen} isOpen={isOpen} />

      {selectedService && (
        <ServicedetailModel
          service={selectedService}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      )}

      <nav
        className={
          "navbar navbar-expand-lg transition-all " +
          (scrollActive
            ? "fixed-top bg-white shadow mt-lg-0"
            : "fixed-top mt-lg-2")
        }
      >
        <div className="container">
          <NavLink className="navbar-brand" to="/" onClick={handleClick}>
            <img src={logo} className="logo-img" alt="" />
          </NavLink>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="fa fa-bars text-white"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li
                className="nav-item"
                onClick={handleMouseLeave}
                ref={servicesRef}
              >
                <a className="nav-link" aria-current="page">
                  Services
                </a>
                <ArrowIcon onClick={() => {}} />
                <ServicesModel
                  services={services}
                  onLearnMoreClick={handleLearnMoreClick}
                  ref={servicesModelRef}
                />
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  onClick={handleAboutClick}
                  //  activeClassName="active"
                  to="/about"
                >
                  About
                </NavLink>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={() => {
                    navigate("/");
                    toggle();
                  }}
                  href="#contact"
                >
                  Contact
                </a>
              </li>
            </ul>
            <div className="d-flex items-center gap-4">
              <div className="header-btn">
                <a
                  href="javascript:;"
                  onClick={handleIsOpen}
                  className="btn start-btn appointment-btn"
                >
                  Get Started
                </a>
              </div>
              <div className="header-btn">
                <a
                  className="btn login-btn"
                  href="https://worldcaremd.navigohealth.com/"
                >
                  Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
