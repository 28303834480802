import React from "react";
import ReactModal from "react-modal";
import howOne from "../../assets/howOne.png";
import howTwo from "../../assets/howTwo.png";
import howThree from "../../assets/howThree.png";
import howDownOne from "../../assets/howDownOne.png";
import howDownTwo from "../../assets/howDownTwo.png";
import howDownThree from "../../assets/howDownThree.png";
import howLogo from "../../assets/howLogo.png";
import CloseIcon from "../../Icons/CloseIcon";
import { HowStyles } from "./HowStyles";

const circleData = [
  {
    title: "Request a consultation",
    description: "with one of our skilled, licensed medical professionals",
    imageSrc: howOne,
    imageBottom: howDownOne,
  },
  {
    title: "Schedule an appointment",
    // description: "any time of the day that’s most convenient for you.",
    description: (
      <span>
        <span className="line-break">any time of the day</span>
        <span> that’s most convenient for you.</span>
      </span>
    ),
    imageSrc: howTwo,
    imageBottom: howDownTwo,
  },
  {
    title: "Talk with a doctor",
    description: "and get a prescription today when medically necessary.",
    imageSrc: howThree,
    imageBottom: howDownThree,
  },
];
console.log("here");

const CircleCard = ({ title, description, imageSrc, imageBottom }) => (
  <div className="circleCard">
    <div className="imageContainer">
      <img src={imageSrc} alt={title} />
    </div>
    <div className="info">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
    <div className="lines">--</div>
    <div className="imageBottom">
      <img src={imageBottom} alt={`${title} bottom icon`} />
    </div>
  </div>
);

const HowItWorks = ({ worksOpen, setWorksOpen }) => (
  <ReactModal
    preventScroll
    onRequestClose={() => setWorksOpen(false)}
    isOpen={worksOpen}
    className="how-it-works-modal"
  >
    <HowStyles>
      <div className="howMainClass">
        <div className="modal-header">
          <img src={howLogo} alt="How It Works Logo" />
          <div className="titleClass">
            <h3>How it works: </h3>
            <span>
              Convenient telemedicine to fit your Union's or employees'
              lifestyle.
            </span>
          </div>
          <div className="close-icon">
            <CloseIcon onClick={() => setWorksOpen(false)} />
          </div>
        </div>

        <div className="circleContainer">
          {circleData.map((card, index) => (
            <CircleCard key={index} {...card} />
          ))}
        </div>
      </div>
    </HowStyles>
  </ReactModal>
);

export default HowItWorks;
