import styled from "styled-components";

export const ServiceModelstyle = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  top: 50px;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  transition: all 0.2s linear;
  padding: 0 15px;
  @media screen and (max-width:992px){
    top: 70px;
  }

  .servicesModal {
    background: #fff;
    max-width: 1242px;
    margin: auto;
    border-radius: 15px;
    overflow: hidden;
    padding: 20px;
    box-shadow: 0px 4px 4px 0px #00000040;

    .service-grid {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .serviceCard {
        flex: 0 0 50%;
        max-width: calc(50% - 15px);
        border-radius: 10px;
        position: relative;
        @media screen and (max-width:767px){
            flex: 0 0 100%;
    max-width: 100%;
    }
        img.serviceCardImage {
          width: 100%;
          min-height: 280px;
          object-fit: cover;
          border-radius: 10px;
        }
        .serviceCardContent {
          position: absolute;
          bottom: 0;
          left: -1px;
          max-width: 280px;
          background: #fff;
          border-top-right-radius: 50px;
          padding: 12px 0;

          h3 {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            padding-right: 24px;
            font-size: 23px;
            font-weight: 600;
            color: #000000;
          }

          p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #243239;
            font-size: 14px;
          }
          .serviceCardButtons {
            display: flex;
            gap: 16px;
            button {
              &:is(.learnMoreButtonClass) {
                background-color: transparent;
                padding: 0px;
                color: #1ac89c;
              }
            }
          }
        }
      }
    }
  }
`;
