import styled from "styled-components";

export const PetDemoStyles = styled.div`
  .popUpMain {
    width: 100%;
    max-width: 100%;
    margin: 0;
    iframe {
      border: none;
      position: absolute;
      left: 50% !important;
      transform: translate(-50%, -50%);
      top: 50% !important;
      height: calc(100vh - 50px);
      max-width: 400px;
      border-radius: 50px;
    }
    svg {
      position: absolute;
      right: 25px;
      top: 25px;
    }
  }
`;
