import React from "react";

const TickIcon = ({ onClick }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0711 2.92891C15.1823 1.0402 12.6711 0 10 0C7.32887 0 4.81766 1.0402 2.92891 2.92891C1.0402 4.81766 0 7.32891 0 10C0 12.6711 1.0402 15.1823 2.92891 17.0711C4.81766 18.9598 7.32891 20 10 20C12.6711 20 15.1823 18.9598 17.0711 17.0711C18.9598 15.1823 20 12.6711 20 10C20 7.32891 18.9598 4.8177 17.0711 2.92891ZM14.7776 7.62004L9.1891 13.2086C9.07469 13.323 8.92473 13.3802 8.7748 13.3802C8.62484 13.3802 8.47488 13.323 8.36047 13.2086L5.22242 10.0705C4.99359 9.84176 4.99359 9.47078 5.22242 9.24191C5.45121 9.01309 5.82223 9.01309 6.05105 9.24191L8.7748 11.9656L13.9489 6.79141C14.1777 6.56258 14.5487 6.56258 14.7776 6.79141C15.0064 7.02023 15.0064 7.39121 14.7776 7.62004Z"
      fill="#39C29E"
    />
  </svg>
);

export default TickIcon;
