import React, { useState } from "react";
import { useForm } from "react-hook-form";
import apiClient from "../../_helper/http-common";
import swal from "sweetalert";
var CryptoJS = require("crypto-js");

function Contact() {
  const {
    register: contactForm,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      subject: "",
      message: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
  });

  const onSubmit = (data) => {
    const USER_HTML = `
    <html>
      <body>
       <div>
          <h2> Contact Us </h2>
          <table>
            <tbody>
              <tr>
                <th>Name :</th><td>${data?.firstName} ${data?.lastName}</td>
              </tr>
              <tr>
                <th>Email :</th><td>${data?.email}</td>
              </tr>
              <tr>
                <th>phone :</th><td>${data?.phone}</td>
              </tr>
              <tr>
                <th>Subject :</th><td>${data?.subject}</td>
              </tr>
            <tr>
              <th>Message :</th><td>${data?.message}</td>
            </tr>
            </tbody>
          </table>
       </div>
      </body>
    </html>
    `;
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify({ ...data, message: USER_HTML }),
      "contact-main@223"
    ).toString();

    apiClient
      .post("https://apiurl.worldcaremd.com/contactUs/sendMail", {
        token: ciphertext,
      })
      .then((res) => {
        if (res.data.status === 200) {
          reset({
            subject: "",
            message: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
          });
          swal({
            icon: "success",
            title: "Thankyou",
            text: "Thank you for your inquiry. A support representative will reach out to you within 24 hours.",
            timer: 3000,
            buttons: false,
          });
        } else {
          swal({
            icon: "error",
            title: "Oops",
            text: res.data.message,
            timer: 3000,
            buttons: false,
          });
        }
      })
      .catch((e) => console.log(e));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row mt-lg-5 mt-sm-4">
        <div className="col-lg-6">
          <div className="name-input  form-group">
            <label>First Name *</label>

            <input
              type="text"
              {...contactForm("firstName", { required: true })}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="name-input form-group">
            <label>Last Name *</label>
            <input
              type="text"
              {...contactForm("lastName", { required: true })}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="email-input form-group">
            <label>Email *</label>
            <input type="text" {...contactForm("email", { required: true })} />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="phone-input">
            <label>Phone *</label>
            <input type="text" {...contactForm("phone", { required: true })} />
          </div>
        </div>
      </div>
      <div className="message-box">
        <label>Message *</label>
        <textarea
          rows="10"
          {...contactForm("message", { required: true })}
        ></textarea>
      </div>
      <button
        type="submit"
        disabled={!isDirty || !isValid}
        className="btn submit-btn"
      >
        Submit
      </button>
    </form>
  );
}

export default Contact;
