import styled from "styled-components";
import ThankyouBack from "../assets/bg_banner.png";
import bgpattern from "../assets/bg-pattern.jpg";

const ThankYouStyleCss = styled.div`
  /* Thank you page */
  button.btn.btn-bg {
    max-width: 277px;
    height: 64px;
    background: #1976f9;
    border-radius: 10px;
    width: 100%;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    border: 2px solid #1976f9;
    z-index: 1;
  }
  button.btn.btn-bg:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    transition: 0.3s ease all;
    transform: scaleX(0);
    transform-origin: center;
    z-index: -1;
  }
  button.btn.btn-bg:hover {
    color: #1976f9;
  }
  button.btn.btn-bg:hover:before {
    transform: scaleX(1);
  }
  .iconImage img {
    max-width: 45px;
  }

  .iconImage h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
  }

  .content_section p {
    margin: 30px 0;
    font-size: 24px;
    max-width: 530px;
    font-weight: 500;
  }

  .button_section a {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    color: #333333;
    transition: 0.3s ease all;
  }

  .button_section a.login:hover {
    color: #1976f9;
  }

  .secondSection {
    background: #7a61fb;
    padding: 50px 0;
  }

  .contentSection,
  .consultContent p {
    font-weight: 500;
    font-size: 24px;
    line-height: 40px;
  }

  .consultContent h4 {
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    text-transform: uppercase;
    color: #333333;
  }

  .contentSection {
    color: #f4fcff;
    max-width: 840px;
  }

  .buttonCount button {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: none;
    background: #7960fa;
    color: #fff;
    font-size: 28px;
    font-weight: 500;
    position: relative;
    z-index: 1;
  }

  .ThirdSection {
    padding: 80px 0;
  }

  .thirdSectionContent .request:not(:last-child) {
    margin-bottom: 60px;
  }

  .consultContent h4 span {
    color: #1976f9;
  }

  .ThirdSection .container:before {
    content: "";
    position: absolute;
    left: 35px;
    height: calc(110vh + 220px);
    width: 2px;
    background: #e7e7e7;
    top: -100px;
  }

  .ThirdSection .container {
    position: relative;
  }

  .name_test::before {
    content: "";
    width: 20px;
    height: 2px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 0;
  }

  .name_test {
    position: relative;
    padding-left: 30px;
  }

  .request:hover .buttonCount button {
    background: #1976f9;
  }

  .firstSection {
    padding: 120px 0 60px;
    background: ${(props) => `url(${ThankyouBack}) no-repeat`};
    background-size: contain;
    background-position-x: right;
    position: relative;
    z-index: 1;
  }
  /* .firstSection:after {
    background: ${(props) => `url(${bgpattern}) no-repeat`};
    background-size: 100%;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: -1;
  } */
  .swiper.swiper-initialized.swiper-horizontal.mySwiper {
    position: relative;
  }

  .swiper-horizontal
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
    .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic
    .swiper-pagination-bullet {
    background: #fff;
    transform: scale(1);
  }
  .swiper-horizontal
    > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 43vw;
    transform: unset;
  }
  .image_section img {
    max-width: 100%;
  }
  header {
    margin-top: 30px;
    position: absolute;
    width: 100%;
    left: 0;
  }
  /* Media Query start */
  .thirdSectionImage {
    width: 100%;
    img {
      width: 100%;
    }
  }

  @media (max-width: 1440px) {
    .iconImage h2 {
      font-size: 40px;
      line-height: normal;
    }
  }

  @media (max-width: 1366px) {
    .iconImage h2 {
      font-size: 32px;
    }

    .content_section p {
      font-size: 22px;
    }
  }
  @media (max-width: 1280px) {
    .contentSection {
      max-width: 100%;
    }
    .swiper-horizontal
      > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
    .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
      left: 88vw;
    }
  }
  @media (max-width: 1199px) {
    button.btn.btn-bg {
      max-width: 265px;
      font-size: 20px;
    }

    .button_section a {
      font-size: 20px;
    }

    .consultContent h4 {
      font-size: 30px;
    }

    .consultContent p {
      font-size: 20px;
      line-height: 1.5;
    }

    .image_section img {
      max-width: 100%;
    }
  }

  @media (max-width: 991px) {
    .content_section p {
      font-size: 18px;
    }

    .iconImage img {
      max-width: 30px;
    }

    .iconImage h2 {
      font-size: 26px;
    }

    button.btn.btn-bg {
      max-width: 200px;
      font-size: 16px;
      height: 55px;
    }

    .consultContent h4 {
      font-size: 24px;
    }

    .consultContent p {
      font-size: 18px;
    }

    .thirdSectionContent .request:not(:last-child) {
      margin-bottom: 30px;
    }

    .buttonCount button {
      width: 40px;
      height: 40px;
      font-size: 22px;
      font-weight: 500;
    }

    .contentSection {
      font-weight: 500;
      font-size: 20px;
      line-height: 1.5;
    }

    .firstSection {
      padding: 50px 0 40px;
    }

    .ThirdSection {
      padding: 50px 0;
    }
    .swiper-horizontal
      > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
    .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
      left: auto;
      right: 0;
    }
  }

  @media (max-width: 767px) {
    .Thank_u {
      margin-top: 57px;
  }
    .content_section {
      margin-bottom: 40px;
    }

    .secondSection .d-flex {
      flex-wrap: wrap;
    }

    .contentSection {
      font-size: 18px;
    }

    .secondSection {
      padding: 40px 0;
    }

    .consultContent h4 {
      font-size: 22px;
    }

    .consultContent p {
      font-size: 16px;
    }

    .ThirdSection .container::before {
      left: 30px;
      height: 100vh;
    }

    button.btn.btn-bg {
      max-width: 160px;
      font-size: 14px;
      height: 45px;
    }

    .button_section a {
      font-size: 16px;
    }

    .ThirdSection {
      padding: 40px 0;
    }

    .thirdSectionContent .request:not(:last-child) {
      margin-bottom: 20px;
    }

    .iconImage h2 {
      font-size: 24px;
    }

    .content_section p {
      margin: 20px 0;
    }
  }
`;

export default ThankYouStyleCss;
