import React from "react";
import { Adapolicystyle } from "./Adapolicystyle";

const Adapolicy = () => {
  return (
    <Adapolicystyle>
      <section className="Banner-privacy">
        <div className="container-fluid p-0">
          <div className="about-banner">
            <div className="container">
              <h1>
                Compliance Policy <span className=""> WorldCareMD </span>{" "}
              </h1>
              <p className="image-aboutus-para">
                WorldCare MD ADA Compliance Policy.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="custom-pad privacy_container">
        <div className="container">
          <section>
            <h2>Introduction</h2>
            <p>
              WorldCare MD is committed to ensuring digital accessibility for
              people with disabilities. We are continually improving the user
              experience for everyone and applying the relevant accessibility
              standards.
            </p>
          </section>

          <section>
            <h2>Accessibility Standards</h2>
            <p>
              Our goal is to conform to the Web Content Accessibility Guidelines
              (WCAG) 2.1 Level AA. These guidelines help make web content more
              accessible for people with a wide range of disabilities, including
              blindness, low vision, deafness and hearing loss, learning
              disabilities, cognitive limitations, limited movement, speech
              disabilities, photosensitivity, and combinations of these.
            </p>
          </section>

          <section>
            <h2>Measures to Support Accessibility</h2>
            <p>
              WorldCare MD takes the following measures to ensure accessibility
              of our website:
            </p>
            <ul>
              <li>Include accessibility throughout our internal policies.</li>
              <li>Integrate accessibility into our procurement practices.</li>
              <li>Appoint an accessibility officer and/or ombudsperson.</li>
              <li>Provide continual accessibility training for our staff.</li>
              <li>Assign clear accessibility goals and responsibilities.</li>
            </ul>
          </section>

          <section>
            <h2>Accessibility Features</h2>
            <p>
              The WorldCare MD website incorporates the following features to
              ensure accessibility:
            </p>
            <ul>
              <li>
                Alternative Text for Images: All images on our website have
                alternative text that conveys the content or function of the
                image.
              </li>
              <li>
                Keyboard Navigation: Our website can be navigated using a
                keyboard.
              </li>
              <li>
                Text Resizing: Users can resize text without loss of content or
                functionality.
              </li>
              <li>
                Color Contrast: We ensure a high contrast between text and
                background to make it easier to read.
              </li>
              <li>
                Screen Reader Compatibility: Our website is compatible with
                screen readers to assist visually impaired users.
              </li>
            </ul>
          </section>

          <section>
            <h2>Feedback</h2>
            <p>
              We welcome your feedback on the accessibility of the WorldCare MD
              website. Please let us know if you encounter accessibility
              barriers:
            </p>
            <ul>
              <li>Phone: (888) 919-6753</li>
              <li>
                E-mail:{" "}
                <a href="mailto:support@worldcaremd.com">
                  support@worldcaremd.com
                </a>
              </li>
              <li>
                Postal Address:
                <br />
                2255 Glades Road
                <br />
                Suite 324A
                <br />
                Boca Raton, Florida 33401
              </li>
            </ul>
            <p>We try to respond to feedback within 2 business days.</p>
          </section>

          <section>
            <h2>Formal Complaints</h2>
            <p>
              We aim to respond to accessibility feedback and complaints within
              15 business days. If you are not satisfied with our response, you
              may contact us to further discuss the issue.
            </p>
          </section>

          <section>
            <h2>Ongoing Efforts</h2>
            <p>
              WorldCare MD is committed to ongoing accessibility improvements.
              We conduct regular audits of our website and strive to ensure it
              remains accessible to all users. We also stay informed about new
              accessibility guidelines and best practices to continue enhancing
              our website.
            </p>
          </section>

          <section>
            <h2>Third-Party Content</h2>
            <p>
              While we cannot control third-party content, we encourage our
              partners and vendors to provide accessible content. We also
              provide guidance on accessibility to third-party content providers
              and require adherence to WCAG standards.
            </p>
          </section>

          <section>
            <h2>Conclusion</h2>
            <p>
              WorldCare MD is dedicated to providing a website that is
              accessible to all users. We continually review and update our
              accessibility policies and practices to ensure we meet the needs
              of our diverse audience.
            </p>
            <p>
              Thank you for visiting our website. We are committed to ensuring
              everyone has equal access to our services and information.
            </p>
          </section>
        </div>
      </div>
    </Adapolicystyle>
  );
};

export default Adapolicy;
