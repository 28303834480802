import React from "react";
import ReactModal from "react-modal";
import CloseIcon from "../../../Icons/CloseIcon";
import TickIcon from "../../../Icons/TickIcon";
import howLogo from "../../../assets/howLogo.png";
import detailBackGround from "../../../assets/detailBackGround.png";
import { ServicesDetailStyle } from "./ServicesDetailStyle";
import Button from "../../../Button";

const ServicedetailModel = ({ isOpen, onClose, service }) => {
  const handleNavigate = () => {
    window.location.href = service?.url;
  };
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="serviceDetailmodal"
    >
      <ServicesDetailStyle>
        <div className="ServiceDetailcontent">
          <div className="modal-header">
            <img src={howLogo} alt="How It Works Logo" />
            <h2>{service?.title}</h2>
            <div className="close-icon">
              <CloseIcon onClick={onClose} />
            </div>
          </div>
          <div className="modal-body">
            <div className="imageClass">
              <img src={service?.image} alt={service?.title} className="" />
            </div>
            <div className="contentClass">
              <p>{service?.description}</p>
              <h3>They can assist with:</h3>
              <ul>
                {service?.list?.map((item, index) => (
                  <>
                    <li key={index}>
                      <TickIcon />
                      {item}
                    </li>
                  </>
                ))}
              </ul>
              <div className="modalFooter">
                <Button className="loginButtonClass" onClick={handleNavigate}>
                  Login
                </Button>
              </div>
            </div>
          </div>
          <div className="bgImage">
            <img src={detailBackGround} alt={service?.title} className="" />
          </div>
        </div>
      </ServicesDetailStyle>
    </ReactModal>
  );
};

export default ServicedetailModel;
