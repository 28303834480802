import styled from "styled-components";

export const HowStyles = styled.div`
  background-color: #fff;
  padding: 41px 56px 41px 44px;
  border-radius: 25px;
  position: relative;
  max-width: 1020px;
  margin: auto;
  @media screen and (max-width: 1199px) {
    overflow-x: hidden;
    padding: 15px;
    height: calc(100vh - 80px);
    max-width: 90%;
  }
  .modal-header {
    justify-content: flex-start;
    gap: 32px;
    margin-bottom: 40px;
    z-index: 1;
    position: relative;
    flex-wrap: wrap;
    .titleClass {
      position: relative;
      h3 {
        font-size: 18px;
        font-weight: 700;
        margin: 0;
      }
      &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        background: #c4c4c4;
        left: -16px;
        top: 0;
      }
    }
    .close-icon {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
  .circleContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 35px;
    @media screen and (max-width: 1199px) {
      gap: 15px;
    }
    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(1, 1fr);
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .circleCard {
      .imageContainer {
        border-radius: 50px;
        margin-bottom: 24px;
        @media screen and (max-width: 1024px) {
          max-width: 50%;
          margin: 0 auto;
        }
        img {
          width: 100%;
        }
      }
      .info {
        text-align: center;
        h3 {
          font-size: 22px;
          margin-bottom: 16px;
          font-weight: 700;
        }
        p {
          margin: 0 0 10px;
        }
      }
      .lines {
        line-height: normal;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
      }
      .imageBottom {
        height: 70px;
        text-align: center;
        border-radius: 30px;
      }
      &:last-child {
        .imageBottom {
          img {
            object-fit: cover;
            object-position: 0 -13px;
          }
        }
      }
      .line-break {
        display: block;
      }
    }
  }
`;
