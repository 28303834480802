import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
import arrowdwnmb from "../../Components/assets/arrow-mb.png";
import arrowdwn from "../../Components/assets/arrow-down.png";
import banner2mask from "../../Components/assets/b-pattern.png";
import banner2maskBackground from "../../Components/assets/group-doct.png";
import bluecircle from "../../Components/assets/bluecircle.png";
import gradientblur from "../../Components/assets/gradientblur.png";
import greenblur from "../../Components/assets/greenblur.png";
import petImage from "../../Components/assets/petImage.png";
import patternLeft from "../../Components/assets/pattern-left.png";
import register from "../../Components/assets/register.svg";
import check from "../../Components/assets/check.svg";
import request from "../../Components/assets/request.svg";
import talk from "../../Components/assets/talk.svg";
import allergies from "../../Components/assets/allergies.svg";
import cold from "../../Components/assets/cold.svg";
import pharyngitis from "../../Components/assets/pharyngitis.svg";
import infection from "../../Components/assets/skin-infection.svg";
import gastroenteritis from "../../Components/assets/gastroenteritis.svg";
import ear from "../../Components/assets/ear-infection.svg";
import pink from "../../Components/assets/pink-eye.svg";
import respiratory from "../../Components/assets/respiratory-Infections.svg";
import sinusitis from "../../Components/assets/sinusitis.svg";
import sprain from "../../Components/assets/sprain-and-strains.svg";
import urinary from "../../Components/assets/urinary-tract-infection.svg";
import consulting from "../../Components/assets/consulting-for-International.svg";
import depression from "../../Components/assets/Depression&Anxiety.svg";
import work from "../../Components/assets/Work-related-stress.svg";
import alcohol from "../../Components/assets/Alcohol-Drug-problems.svg";
import grief from "../../Components/assets/Grief&loss.svg";
import eating from "../../Components/assets/Eating-Disorders.svg";
import stress from "../../Components/assets/Stress&Pain-management.svg";
import marriage from "../../Components/assets/Marriage-relationship-problems.svg";
import personal from "../../Components/assets/Personal-Distress.svg";
import Eldercare from "../../Components/assets/Eldercare-childcare-issues.svg";
import major from "../../Components/assets/Major-life-event.svg";
import concerns from "../../Components/assets/Health-care- concerns.svg";
import much from "../../Components/assets/MUCH MORE.svg";
import phone from "../../Components/assets/phone-left.png";
import mail from "../../Components/assets/phone-right.png";
import slider1 from "../../Components/assets/slider1.png";
import slider2 from "../../Components/assets/slider2.png";
import slider3 from "../../Components/assets/slider3.png";
import slider4 from "../../Components/assets/slider4.png";
import Appointment from "./Appointment";
import Contact from "./Contact";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import _ from "lodash";
import HowItWorks from "./Components/HowItWorks";
import RightArrowIcon from "../Icons/RightArrowIcon";
import PetDemo from "./Components/PetDemo/PetDemo";

const Home = () => {
  const [isOpen, setIsOpen] = useState();
  const [worksOpen, setWorksOpen] = useState(false);
  const [demoOpen, setDemoOpen] = useState(false);

  const handleIsOpen = (value) => {
    setIsOpen(!isOpen);
  };
  const HEALTH_SECTION = [
    {
      mainHeading: "Typical general health & dermatology*",
      insideData: [
        { image: allergies, title: "Allergies" },
        { image: cold, title: "Cold & flu" },
        { image: pharyngitis, title: "Pharyngitis" },
        { image: infection, title: "Skin infections" },
        { image: gastroenteritis, title: "Gastroenteritis" },
        { image: ear, title: "Ear infection" },
        { image: pink, title: "Pink eye" },
        { image: respiratory, title: "Respiratory infections" },
        { image: sinusitis, title: "Sinusitis" },
        { image: sprain, title: "Sprains and strains" },
        { image: urinary, title: "Urinary tract infection" },
        { image: consulting, title: "Eczema" },
      ],
    },
    {
      mainHeading: "Typical behavioral health*",
      insideData: [
        { image: depression, title: "Depression & anxiety" },
        { image: work, title: "Work-related stress" },
        { image: alcohol, title: "Alcohol or drug problems" },
        { image: grief, title: "Grief and loss" },
        { image: eating, title: "Eating disorders" },
        { image: stress, title: "Stress & pain management" },
        {
          image: marriage,
          title: "Marriage or relationship problems",
        },
        { image: personal, title: "Personal distress" },
        {
          image: Eldercare,
          title: "Eldercare, childcare, and parenting issues",
        },
        { image: major, title: "Major life event: birth, death, accidents" },
        { image: concerns, title: "Health care concerns" },
        { image: much, title: "And much more!" },
      ],
    },
  ];

  const data = [
    "97% Member satisfaction",
    "92% Of medical issues resolved",
    "98% Client retention",
    "<15 Min response time",
    "98% Of members recommend ",
    "Top quality, board-certified doctors",
    "Get peace of mind",
    "Prescriptions sent to your local pharmacy",
  ];

  const firstFive = data.slice(0, 5);
  const lastThree = data.slice(5);

  return (
    <>
      <Appointment handleIsOpen={handleIsOpen} isOpen={isOpen} />
      <HowItWorks worksOpen={worksOpen} setWorksOpen={setWorksOpen} />
      <PetDemo setDemoOpen={setDemoOpen} demoOpen={demoOpen} />
      <section className="first-banner">
        <div className="first-banner-inner">
          <div className="container">
            <div className="heroBanner">
              <div className="textBlock">
                <div className="banner1-content">
                  <h1>
                    Take charge of your health now with
                    <span className="blue-text">WorldCareMD</span>
                  </h1>
                  <p className="paraheader">
                    Convenient telemedicine to fit your Union or employees’
                    <br />
                    lifestyle.
                  </p>
                  <div className="dots_group">
                    <span className="dots"></span>
                    <span className="dots"></span>
                  </div>
                  <div className="banner1-btn">
                    <div className="header-btn">
                      <button
                        onClick={() => setWorksOpen(true)}
                        className="btn start-btn btn-green appointment-btn howClass"
                      >
                        How it works <RightArrowIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="docterImages">
                <Swiper
                  speed={2000}
                  slidesPerView={1}
                  spaceBetween={0}
                  effect={"fade"}
                  fadeEffect={{ crossFade: true }}
                  modules={[EffectFade, Navigation, Pagination, Autoplay]}
                  autoplay={{
                    delay: 4500,
                    disableOnInteraction: false,
                  }}
                >
                  {[
                    { image: slider1, title: "Gastroenterioiogy" },
                    {
                      image: slider2,
                      title: "Allergist",
                    },
                    {
                      image: slider3,
                      title: "Psychiatrist",
                    },
                    {
                      image: slider4,
                      title: "Dermatologist",
                    },
                  ]?.map(({ image }, index) => (
                    <SwiperSlide key={"image" + index}>
                      <img src={image} className="w-100" alt="sliderDoctor" />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="second-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner2-image">
                <img src={banner2maskBackground} className="w-100" alt="" />
                <img src={banner2mask} className="w-100 b-pattern" alt="" />
                <div className="gr-blur">
                  <img src={greenblur} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="banner2-content">
                <h2>
                  <span className="text-green">About </span>WorldCareMD
                </h2>
                <p>
                  WorldCareMD member's benefit from one of the country's largest
                  networks of telemedicine physicians.
                </p>
                <p>
                  Over the phone or via secure bi-directional video and email,
                  our national network of board certified, licensed Internal
                  Medicine and Emergency Room physicians diagnose ailments,
                  recommend therapy, and prescribe drugs for its members.
                </p>
                {/* <a href="#!" className="btn read-more-btn">
                  Read More
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="third-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner3-content">
                  <h2>
                    <span className="text-green">Why </span>WorldCare MD
                  </h2>
                  <div className="wrapper-flex d-flex flex-wrap justify-content-between">
                    <div className="rightDiv">
                      {firstFive.map((row, index) => (
                        <div className="company-info1" key={"first" + index}>
                          <div className="info">
                            <img className="me-3" src={check} alt="" />
                            <span>{row}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="leftDiv">
                      {lastThree.map((row, index) => (
                        <div className="company-info1" key={"last" + index}>
                          <div className="info">
                            <img className="me-3" src={check} alt="" />
                            <span>{row}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="blue-wraper">
                    <img src={bluecircle} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gradient-wraper">
          <img src={gradientblur} alt="" />
        </div>
        <div className="arrow-container">
          <img src={arrowdwn} alt="" className="desktop-arrow" />
          <img src={arrowdwnmb} alt="" className="mb-arrow" />
        </div>
      </section>

      <section className="fourth">
        <div className="container">
          <h2 className="text-center">
            How to get <span className="text-green">started</span>
          </h2>
          <div className="start-map-content">
            <div className="row">
              {_.map(
                [
                  {
                    image: register,
                    section: "register",
                    title: "Register",
                    content: `Setup your profile once you're invited and complete your electronic health record, then login to your account anytime 24/7 to request a consult.`,
                  },
                  {
                    image: request,
                    section: "request",
                    title: "Request a consult",
                    content: `Consult with one of our board-certified physicians via secure bi-directional video or phone day or night.`,
                  },
                  {
                    image: talk,
                    section: "talk",
                    title: "Talk with doctor",
                    content: `Receive diagnosis and treatment by our physicians to get quality care and peace of mind where ever you are day or night.`,
                  },
                ],
                ({ image, section, title, content }, index) => (
                  <div className="col-lg-4" key={section}>
                    <div className={`${section} text-center mt-lg-0`}>
                      <img src={image} alt="" />
                    </div>
                    <div className={`${section}-content`}>
                      <h3 className="text-center">
                        {index + 1}. {title}
                      </h3>
                      <p className="text-center">{content}</p>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
      <hr className="" />
      <section className="fifth" id="services">
        <div className="container">
          <h2 className="text-center mb-5">
            We treat a broad range of <br />
            <span className="text-green">non-emergency medical</span> and{" "}
            <span className="text-green">behavioral health conditions</span>
            <br />
            including <span className="text-green">pet care</span> &{" "}
            <span className="text-green">training</span>
          </h2>
          {_.map(HEALTH_SECTION, ({ mainHeading, insideData }, index) => (
            <div key={mainHeading} className="Behavioral-health">
              <p
                className={`health text-center text-lg-start ${
                  index === 1 ? "mt-5" : ""
                }`}
              >
                {mainHeading}
              </p>
              <div className="health-problems">
                <div className="row">
                  {_.map(insideData, ({ image, title }) => (
                    <div className="box-cstm">
                      <div className="healthbox-border" key={title}>
                        <div className="health-box">
                          <img src={image} className="me-3" alt="" />
                          <span>{title}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
          <p className={`health text-center text-lg mt-5 }`}>
            *We treat a wide range of conditions not limited to the above.
          </p>
        </div>
      </section>
      {/* <hr className="" /> */}
      <section className="petSection position-relative" id="pet">
        <div className="container">
          <div className="row">
            <div className="available-data">
              <h2>
                Convenient <span className="text-green">online care </span>
                for <span className="text-green">pets</span> & their{" "}
                <span className="text-green">humans</span> delivered to you.
              </h2>
              <p>
                Virtual veterinary care bridges the gap with convenient,
                high-quality veterinary telemedicine to meet your pet's needs
                when in-clinic care isn't necessary.
              </p>
              <button
                onClick={() => {
                  setDemoOpen(true);
                }}
                className="btn btn-green"
              >
                Watch Demo
              </button>
            </div>
            <div className="img-fluid">
              <img src={petImage} alt="" />
            </div>
          </div>
        </div>
        <div className="blue-img position-absolute">
          <img src={bluecircle} alt="" className="w-100" />
        </div>
        <div className="pattern-left position-absolute">
          <img src={patternLeft} alt="" className="w-100" />
        </div>
      </section>
      <section className="form" id="contact">
        <div className="container">
          <h2 className="text-center">Contact Us</h2>
          <div className="justify-content-center contact-form">
            <div className="row">
              <div className="col-md-6">
                <div className="chat-box">
                  <img src={phone} alt="" />
                  <div>
                    <p>Monday-Sunday: 24/7</p>
                    <p>(888) 919-6753</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="chat-box">
                  <img src={mail} alt="" />
                  <div>
                    <p>We will reply to you as early as possible:</p>
                    <p>
                      <a className="text-white" href="#">
                        support@worldcaremd.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Contact />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
