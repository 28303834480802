import React from "react";
import styled from "styled-components";

// Styled button component using styled-components
const StyledButton = styled.button`
  padding: 8px 34px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  background-color: #095dd5;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

// Reusable Button component that accepts all button props
const Button = ({
  onClick,
  type = "button",
  disabled = false,
  children,
  className,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={className}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
