import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import Privacy from "./Components/Privacy/Privacy";
import About from "./Components/About/About";
import ThankYouPage from "./Components/Thankyou";
import Privacypolicy from "./Components/PrivacyPolicy/Privacypolicy";
import TermAndConditions from "./Components/TermAndConditions/TermAndConditions";
import CookiePolicy from "./Components/CookiePolicy/CookiePolicy";
import Adapolicy from "./Components/AdaPolicy/Adapolicy";

function App() {
  const { pathname } = useLocation();
  console.log("pathname", pathname);
  // here is code
  return (
    <>
      {pathname !== "/thankyou" && <Header />}
      {/* <Header /> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/about" element={<About />} />
        <Route path="/thankyou" element={<ThankYouPage />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/term-and-conditions" element={<TermAndConditions />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/ada-policy" element={<Adapolicy />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
