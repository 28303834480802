import React from "react";
import logoFooter from "../assets/logoFooter.png";
import facebook from "../assets/facebook.svg";
import instagram from "../assets/instagram.svg";
import linkedin from "../assets/linkedin.svg";
import gplay from "../assets/playstore-soon.png";
import apsStore from "../assets/ComingSoon-AppStore.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const toggle = () => {
    var element = document.getElementById("navbarSupportedContent");
    element.classList.remove("show");
  };

  return (
    <div>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-logo">
                <img src={logoFooter} alt="" />
              </div>
            </div>
            <div className="col-lg-3">
              <ul className="address">
                <li className="w-100">
                  <p className="down-heading">Address</p>
                  <p className="mb-0">
                    2255 Glades Road <br />
                    Suite 324A
                    <br />
                    Boca Raton, Florida 33431
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <ul className="footer-links">
                <li>
                  <a href="#">Events</a>
                </li>
                <li>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/about");
                      window.scrollTo(0, 0);
                      toggle();
                    }}
                    href="#"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                      toggle();
                    }}
                    href="#contact"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <ul className="footer-links">
                <li>
                  <a href="/privacy-policy" target="_blank">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="/term-and-conditions" target="_blank">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="/cookie-policy" target="_blank">
                    Cookie Policy
                  </a>
                </li>
                <li>
                  <a href="/ada-policy" target="_blank">
                    ADA Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <p className="down-heading">Download the app</p>
              <ul className="icons-payment d-flex flex-wrap gap-2">
                <li>
                  <img src={gplay} alt="" className="w-100" />
                </li>
                <li>
                  <a
                    href="https://apps.apple.com/us/app/worldcare-md/id6478173716"
                    target="_blank"
                    className="iconLink"
                  >
                    <img src={apsStore} alt="" className="w-100" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="sub-footer">
        <div className="container">
          <div className="sub-footer-content">
            <div className="social d-flex gap-3">
              {/* <a href="#!" className="me-1">
                <img src={facebook} alt="" />
              </a>
              <a href="#!" className="me-1">
                <img src={instagram} alt="" />
              </a> */}
              <a href="#!" className="me-1">
                <img src={linkedin} alt="" />
              </a>
            </div>
            <div className="copyright mt-3 mt-lg-0">
              <p className="mb-0">
                © <span className="">{new Date().getFullYear()}</span> All
                Rights Reserved.
              </p>
            </div>
            {/* <div className="links mt-3 mt-lg-0">
              <p className="mb-0">
                Made with <span className="red-heart">❤️</span> in Florida
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;

const tooltipTriggerList = document.querySelectorAll(
  '[data-bs-toggle="tooltip"]'
);
const tooltipList = [...tooltipTriggerList];
