import React from "react";
import ReactModal from "react-modal";
import CloseIcon from "../../../Icons/CloseIcon";
import { PetDemoStyles } from "./PetDemoStyles";

const PetDemo = ({ demoOpen, setDemoOpen }) => {
  const closeModal = () => {
    setDemoOpen(false);
  };

  return (
    <ReactModal
      isOpen={demoOpen}
      onRequestClose={closeModal}
      className="petDemoPop"
    >
      <PetDemoStyles>
        <div className="popUpMain">
          <CloseIcon onClick={closeModal} />
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/FCQANBGtMsQ"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              border: "none",
              position: "absolute",
              left: 0,
              top: 0,
            }}
            title="PetDemo YouTube Shorts Video"
          ></iframe>
        </div>
      </PetDemoStyles>
    </ReactModal>
  );
};

export default PetDemo;
