import React from "react";
import { TermAndConditionStyleCss } from "./TermConditions.style";

const TermAndConditions = () => {
  return (
    <TermAndConditionStyleCss>
      <div>
        <div className="privacy">
          <div className="container-fluid p-0 ">
            <div className="policy-heading">
              <h2>Term and Conditions</h2>
              <p>Last updated: June 2022</p>
            </div>
          </div>
        </div>
        <div className="container  pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 ">
              <div className="privacy-content mt-4">
                <br />
                <p className="privacy-para">
                  During a telephone or video consultation, WorldCareMD
                  physicians have access to a large variety of medications that
                  can be provided as necessary. An email consultation will not
                  result in the prescription of any drugs. Physician-prescribed
                  drugs are e-prescribed to a member's preferred pharmacy for
                  local pick-up or shipped to a member's home or office via mail
                  order pharmacy.
                </p>
                <p className="privacy-para">
                  If a doctor does prescribe medication, the supply will
                  normally be limited to thirty days. Chronic illness patients
                  should see their primary care physician (PCP) or other
                  specialists.
                </p>
                <p className="privacy-para mt-4">
                  As part of a medical consultation, WorldCareMD physicians will
                  not prescribe prescriptions for substances that are restricted
                  by the DEA or that have the potential for harm, misuse, or
                  addiction. Visit
                </p>
                <p className="privacy-para">
                  http://www.deadiversion.usdoj.gov/schedules/index.html for a
                  current list of DEA controlled substances.
                </p>
                <p className="privacy-para ">
                  All prescriptions are filled according to WorldCareMD's
                  prescription policy and at the consulting physician's
                  discretion.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TermAndConditionStyleCss>
  );
};

export default TermAndConditions;
