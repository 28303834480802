import React, { forwardRef } from "react";
import RightArrowIcon from "../../../Icons/RightArrowIcon";
import { ServiceModelstyle } from "./ServiceModelstyle";
import Button from "../../../Button";

const ServiceCard = ({
  key,
  imageSrc,
  title,
  description,
  onLearnMore,
  url,
}) => {
  const handleNavigate = (url) => {
    window.location.href = url;
  };

  return (
    <div className="serviceCard">
      <img
        src={imageSrc}
        height={50}
        alt={title}
        className="serviceCardImage"
      />
      <div className="serviceCardContent">
        <h3>{title}</h3>
        <p>{description}</p>
        <div className="serviceCardButtons">
          <Button
            className="loginButtonClass"
            onClick={() => handleNavigate(url, key)}
          >
            Login
          </Button>
          <Button className="learnMoreButtonClass" onClick={onLearnMore}>
            Learn More
          </Button>
        </div>
      </div>
    </div>
  );
};

const ServicesModel = forwardRef(
  ({ services, onLearnMoreClick, setIsModalOpen, isModalOpen }, ref) => {
    return (
      <ServiceModelstyle ref={ref}>
        <div
          preventScroll
          onRequestClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          className="servicesModal"
        >
          <div className="service-grid">
            {services.map((service, index) => (
              <ServiceCard
                key={index}
                imageSrc={service.image}
                title={service.title}
                description={service.description}
                onLearnMore={() => onLearnMoreClick(service)}
                url={service?.url}
              />
            ))}
          </div>
        </div>
      </ServiceModelstyle>
    );
  }
);

export default ServicesModel;
